<template>
    <b-tabs content-class="mt-1">
        <b-tab title="Información">
            <info-paciente></info-paciente>
        </b-tab>
        <b-tab title="Asistencias" lazy>
            <asistencias-paciente></asistencias-paciente>
        </b-tab>
        <b-tab title="Mediciones" lazy>
            <mediciones-paciente></mediciones-paciente>
        </b-tab>
    </b-tabs>
</template>
<script>
import InfoPaciente from "./tabs/InfoPaciente.vue";
import AsistenciasPaciente from "./tabs/AsistenciasPaciente.vue";
import MedicionesPaciente from "./tabs/MedicionesPaciente.vue";
export default {
    components: {
        InfoPaciente,
        AsistenciasPaciente,
        MedicionesPaciente,
    },
};
</script>
