<template>
    <div>
        <b-row class="match-height">
            <b-col>
                <b-card :class="{ 'overflow-auto': mostrarMas === true }">
                    <b-card-title>Últimas mediciones en 24 horas</b-card-title>
                    <app-timeline>
                        <app-timeline-item
                            v-for="(item, index) in mediciones"
                            :key="index"
                            :title="item.titulo"
                            :subtitle="item.subtitulo"
                            :time="item.time"
                            :variant="item.variant"
                        />
                    </app-timeline>
                    <b-row class="justify-content-center">
                        <b-button
                            variant="flat-dark"
                            size="sm"
                            pill
                            class="my-2"
                            @click=""
                            >Mostrar más...</b-button
                        >
                    </b-row>
                </b-card>
            </b-col>
            <b-col>
                <b-card>
                    <b-card-title>Mediciones por equipos</b-card-title>
                    <b-row>
                        <b-col>
                            <div
                                v-for="(dispositivo, index) in dispositivos"
                                :key="index"
                                class="py-1 border-bottom cursor-pointer dispositivos"
                                @click="abrirModalMedicionesDispositivos"
                            >
                                <b-col>
                                    <b-row>
                                        <img
                                            :src="dispositivo.dispositivo"
                                            class="rounded"
                                            height="80px"
                                            width="80px"
                                        />
                                        <b-col>
                                            <p class="font-weight-bolder my-0">
                                                Dispositivo
                                            </p>
                                            <span>{{
                                                dispositivo.nombre
                                            }}</span>

                                            <p
                                                class="font-weight-bolder my-0 mt-1"
                                            >
                                                Usado última vez
                                            </p>
                                            <span>{{
                                                dispositivo.usado_ultima_vez
                                            }}</span>
                                        </b-col>
                                        <b-col
                                            sm="12"
                                            xl="auto"
                                            class="text-center"
                                        >
                                            <p class="font-weight-bolder">
                                                Veces usado
                                            </p>
                                            <b-row>
                                                <b-col class="px-0"
                                                    ><p>Mes</p>
                                                    <b-badge
                                                        variant="success"
                                                        >{{
                                                            dispositivo.veces_usado_mes
                                                        }}</b-badge
                                                    >
                                                </b-col>
                                                <b-col class="px-0"
                                                    ><p>Sem.</p>
                                                    <b-badge variant="danger">
                                                        {{
                                                            dispositivo.veces_usado_semana
                                                        }}</b-badge
                                                    >
                                                </b-col>
                                                <b-col class="px-0"
                                                    ><p>Hoy</p>
                                                    <b-badge variant="info">
                                                        {{
                                                            dispositivo.veces_usado_hoy
                                                        }}</b-badge
                                                    >
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <!-- modales -->
        <modal-mediciones-dispositivos
            ref="refmodalMedicionesDispositivos"
        ></modal-mediciones-dispositivos>
    </div>
</template>
<script>
import { ref, onMouted } from "@vue/composition-api";
export default {
    components: {
        ModalMedicionesDispositivos: () =>
            import("../modals/ModalMedicionesDispositivos.vue"),
    },
    setup(props, context) {
        const mediciones = ref([
            {
                titulo: "Blood pressure",
                subtitulo: "Medición con resultados negativos",
                variant: "danger",
                time: "Hace 2 minutos",
            },
            {
                titulo: "Blood pressure",
                subtitulo: "Medición tomada correctamente",
                variant: "success",
                time: "Hace 2 minutos",
            },
            {
                titulo: "Blood pressure",
                subtitulo: "Dispositivo mal colocado",
                variant: "warning",
                time: "Hace 2 minutos",
            },
        ]);

        const dispositivos = ref([
            {
                dispositivo: "/img/dispositivos/blood_pressure.jpg",
                nombre: "Blood pressure",
                usado_ultima_vez: "2 jul 2022 12:23 pm",
                veces_usado_mes: 120,
                veces_usado_semana: 24,
                veces_usado_hoy: 2,
            },
            {
                dispositivo: "/img/dispositivos/body_composition.jpg",
                nombre: "Body composition",
                usado_ultima_vez: "30 jul 2022 04:02 pm",
                veces_usado_mes: 87,
                veces_usado_semana: 20,
                veces_usado_hoy: 4,
            },
            {
                dispositivo: "/img/dispositivos/weight_scale.jpeg",
                nombre: "Body composition",
                usado_ultima_vez: "04 ago 2022 06:12 pm",
                veces_usado_mes: 43,
                veces_usado_semana: 24,
                veces_usado_hoy: 10,
            },
        ]);

        const mostrarMas = ref(false);

        function mostrarMasData() {
            mostrarMas.value = true;
            const data = {
                titulo: "Blood pressure",
                subtitulo: "Medición tomada correctamente",
                variant: "success",
                time: "Hace 2 minutos",
            };
            mediciones.value.push(data);
        }

        function abrirModalMedicionesDispositivos() {
            context.refs.refmodalMedicionesDispositivos.toggle();
        }

        return {
            mediciones,
            mostrarMasData,
            mostrarMas,
            dispositivos,
            abrirModalMedicionesDispositivos,
        };
    },
};
</script>
