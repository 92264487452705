<template>
    <div>
        <b-row>
            <b-col>
                <indicadores
                    :indicadores="indicadores"
                    @date="getIndicadores"
                />
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <div class="card p-2 mb-3">
                    <h3>Listado de asistencias</h3>
                    <b-row class="d-flex justify-content-end my-2">
                        <b-col cols="auto">
                            <b-form-input></b-form-input>
                        </b-col>
                        <b-col cols="auto">
                            <b-button variant="dark" pill class="mr-1 p-50">
                                <feather-icon
                                    icon="SearchIcon"
                                    size="20"
                                ></feather-icon>
                            </b-button>
                            <b-button
                                variant="gradient-primary"
                                pill
                                class="p-50"
                                @click="buttonOrderBy"
                                v-b-tooltip.hover.top="
                                    orderBy == 'desc'
                                        ? 'Ordenar ascendentemente'
                                        : 'Ordernar descendentemente'
                                "
                            >
                                <feather-icon
                                    icon="ArrowUpIcon"
                                    size="20"
                                    :class="{ 'text-dark': orderBy == 'asc' }"
                                ></feather-icon>
                                <feather-icon
                                    icon="ArrowDownIcon"
                                    size="20"
                                    :class="{ 'text-dark': orderBy == 'desc' }"
                                ></feather-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                    <div class="row justify-content-center">
                        <card-asistencia
                            v-for="(asistencia, index) in asistencias"
                            :key="index"
                            :asistencia="asistencia"
                        />
                    </div>
                </div>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import apexChatData from "@/components/apexChartData.js";
import { ref, onMounted } from "@vue/composition-api";
export default {
    components: {
        Indicadores: () => import("@/components/indicadores.vue"),
        CardAsistencia: () =>
            import("@/components/asistencias/card_asistencia.vue"),
    },
    setup(props) {
        const orderBy = ref("asc");

        const asistencias = ref([
            {
                id: 1,
                estado: 0,
            },
            {
                id: 2,
                estado: 0,
            },
            {
                id: 3,
                estado: 1,
            },
            {
                id: 4,
                estado: 3,
            },
        ]);

        const indicadores = ref([
            {
                nombre: "Asistencias tomadas",
                numero: 123,
            },
            {
                nombre: "Asistencias reprogramadas",
                numero: 123,
            },
            {
                nombre: "Asistencias tomadas",
                numero: 123,
            },
            {
                nombre: "Asistencias tomadas",
                numero: 123,
            },
        ]);

        function buttonOrderBy() {
            orderBy.value == "asc"
                ? (orderBy.value = "desc")
                : (orderBy.value = "asc");
        }

        function getIndicadores(date = null) {
            console.log(date);
        }

        return {
            orderBy,
            asistencias,
            buttonOrderBy,
            indicadores,
            getIndicadores,
        };
    },
};
</script>
<style lang="css">
.card-asistencia {
    min-width: 400px;
    border-radius: 20px;
}
</style>
