<template>
    <div>
        <b-row class="match-height">
            <b-col>
                <b-card>
                    <b-card-title>Datos personales</b-card-title>
                    <b-card-body>
                        <b-row class="align-items-center">
                            <b-col cols="auto">
                                <b-avatar
                                    rounded
                                    variant="info"
                                    size="6em"
                                ></b-avatar>
                            </b-col>
                            <b-col>
                                <b-row>
                                    <b-col>
                                        <h3 class="font-weight-bold text-left">
                                            Fernando Camilo Galán Sánchez
                                        </h3>
                                        <b-badge variant="success"
                                            >Activo</b-badge
                                        >
                                        <!-- <b-badge variant="info"
                                            >Parcial</b-badge
                                        >
                                        <b-badge variant="danger"
                                            >Inactivo</b-badge
                                        > -->
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row class="border-top my-2">
                            <b-col class="my-2">
                                <span class="font-weight-bold">Genero: </span>
                                <p>Masculino</p>
                                <span class="font-weight-bold">Edad: </span>
                                <p>21</p>
                                <span class="font-weight-bold">Teléfono: </span>
                                <p>3209596193</p>
                                <span class="font-weight-bold">Correo:</span>
                                <p>gian.kgalan@gmail.com</p>
                            </b-col>
                        </b-row>
                        <b-button
                            variant="info"
                            id="popover-target-download-historial"
                        >
                            <feather-icon icon="DownloadIcon" />
                            Descargar historial
                        </b-button>
                        <b-popover
                            target="popover-target-download-historial"
                            triggers="click"
                            placement="right"
                            :show.sync="popoverShow"
                        >
                            <b-row
                                class="mx-2 text-center justify-content-center"
                            >
                                <p class="">
                                    Selecciona por favor desde qué fecha se
                                    descargará el historial
                                </p>
                                <b-form-datepicker
                                    v-model="datePickerDownload"
                                    :date-format-options="{
                                        year: 'numeric',
                                        month: 'short',
                                        day: '2-digit',
                                        weekday: 'short',
                                    }"
                                    locale="es"
                                    placeholder="Fecha inicio"
                                ></b-form-datepicker>
                                <b-button
                                    class="my-2"
                                    @click="popoverShow = false"
                                    >Descargar</b-button
                                >
                            </b-row>
                        </b-popover>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col xl="6" sm="12">
                <b-row>
                    <b-col>
                        <b-card
                            class="overflow-auto"
                            style="height: calc(100vh - 70vh)"
                        >
                            <b-card-title>Riesgos</b-card-title>
                            <b-row
                                :class="`justify-content-end sticky-top align-items-baseline p-0 py-1 zindex-1 ${
                                    canAdd || canEdit
                                        ? 'bg-white'
                                        : 'float-right'
                                }`"
                            >
                                <b-col>
                                    <v-select
                                        v-if="canAdd"
                                        v-model="riesgosSelect"
                                        :options="riesgosOptions"
                                        cleareable
                                        class="animate__animated animate__bounceIn"
                                    />
                                    <small v-show="canEdit" class="text-muted"
                                        >{{ checksRiesgos.length }} riesgos
                                        seleccionados</small
                                    >
                                </b-col>
                                <b-button
                                    variant="primary"
                                    pill
                                    size="sm"
                                    class="mr-1"
                                    :class="{ disable: canEdit == true }"
                                    @click="canAdd = !canAdd"
                                >
                                    <feather-icon
                                        icon="PlusIcon"
                                        size="15"
                                    ></feather-icon>
                                </b-button>
                                <b-button
                                    variant="success"
                                    pill
                                    size="sm"
                                    class="mr-1"
                                    :class="{ disable: canAdd == true }"
                                    @click="canEdit = !canEdit"
                                >
                                    <feather-icon
                                        icon="EditIcon"
                                        size="15"
                                    ></feather-icon>
                                </b-button>
                                <b-button
                                    v-if="checksRiesgos.length"
                                    variant="danger"
                                    pill
                                    size="sm"
                                    class="mr-1 animate__animated animate__faster animate__jello"
                                    @click="canEdit = !canEdit"
                                >
                                    <feather-icon
                                        icon="DeleteIcon"
                                        size="15"
                                    ></feather-icon> </b-button
                            ></b-row>
                            <b-row>
                                <b-col>
                                    <b-form-checkbox-group
                                        v-model="checksRiesgos"
                                        class="d-flex flex-column"
                                    >
                                        <div
                                            v-for="(riesgo, index) in riesgos"
                                            :key="index"
                                            class="align-items-center zindex-0"
                                        >
                                            <div class="row mx-1 py-1">
                                                <b-form-checkbox
                                                    :value="riesgo.nombre"
                                                    v-if="canEdit"
                                                    class="animate__animated animate__fast animate__bounceInLeft"
                                                />
                                                <span class="mr-2">{{
                                                    riesgo.nombre
                                                }}</span>
                                                <b-badge
                                                    v-if="riesgo.estado == 3"
                                                    pill
                                                    variant="danger"
                                                    >Grave</b-badge
                                                >
                                                <b-badge
                                                    v-if="riesgo.estado == 2"
                                                    pill
                                                    variant="warning"
                                                    >Medio</b-badge
                                                ><b-badge
                                                    v-if="riesgo.estado == 1"
                                                    pill
                                                    variant="success"
                                                    >Bajo</b-badge
                                                >
                                            </div>
                                        </div>
                                    </b-form-checkbox-group>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-card class="d-flex overflow-auto">
                            <b-card-title>Dispositivos</b-card-title>
                            <div>
                                <b-row class="flex-nowrap align-items-center">
                                    <b-col class="justify-content-end">
                                        <b-button
                                            variant="dark"
                                            pill
                                            @click="abrirModalSolicitadSoporte"
                                            >Soporte</b-button
                                        >
                                    </b-col>
                                    <b-col
                                        align="center"
                                        v-for="col in 5"
                                        :key="index"
                                        class="dispositivo m-1"
                                    >
                                        <div class="rounded dispositivo-foto">
                                            <b-badge
                                                variant="success"
                                                class="float-right"
                                                ><feather-icon
                                                    icon="CheckIcon"
                                                ></feather-icon
                                            ></b-badge>
                                        </div>

                                        <b-row>
                                            <h6 class="mt-1 my-0">
                                                Nombre del dispositivo
                                            </h6>
                                            <small
                                                >Tenencia desde 02 de julio
                                                2022</small
                                            >
                                        </b-row>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-card>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <!-- modales -->
        <modal-general
            ref="refmodalSolicitarSoporte"
            title="Solicitar soporte"
            size="md"
            @aceptar="enviarSoporte"
        >
            <template v-slot:default>
                <div>
                    <label class="text-center">Selecciona un dispositivo</label>
                    <v-select
                        class="mb-2"
                        :options="[
                            'Blood pressure',
                            'Weight scale',
                            'Body composition',
                        ]"
                    ></v-select>
                    <label>Describe la situación</label>
                    <b-textarea></b-textarea>
                </div>
            </template>
        </modal-general>
    </div>
</template>
<script>
import { ref, onMounted } from "@vue/composition-api";
export default {
    emits: ["aceptar"],
    setup(props, context) {
        const riesgos = ref([
            {
                nombre: "Viruela",
                estado: 1,
            },
            {
                nombre: "Covid",
                estado: 2,
            },
            {
                nombre: "Tuberculosis",
                estado: 2,
            },
            {
                nombre: "Fiebre",
                estado: 2,
            },
        ]);
        const canEdit = ref(false);
        const canAdd = ref(false);

        const riesgosSelect = ref();
        const riesgosOptions = ref(["Acné", "Apnea", "Covid"]);

        const checksRiesgos = ref([]);

        const datePickerDownload = ref("");
        const popoverShow = ref(false);

        function enviarSoporte() {
            setTimeout(() => {
                this.$nextTick(() => {
                    this.$bvModal.hide("modal-soporte");
                });
            }, 2000);
        }

        function abrirModalSolicitadSoporte() {
            context.refs.refmodalSolicitarSoporte.toggle();
        }

        return {
            checksRiesgos,
            riesgos,
            canEdit,
            canAdd,
            riesgosSelect,
            riesgosOptions,
            datePickerDownload,
            popoverShow,
            enviarSoporte,
            abrirModalSolicitadSoporte,
        };
    },
};
</script>
<style lang="css">
.dispositivo {
    width: 10em;
    height: 10em;
}
.dispositivo-foto {
    background-image: url("/img/omron-logo-og-1200px.png");
    background-size: 100% 100%;
    height: 6em;
    width: 6em;
}
</style>
